<template>

<app-item  route="Request" :params="{ request: request.id }">

	<app-item-title :text="$highlight(request.term, highlight)" :subtitle="request.date | formatDate('h:mma, MMMM Do YYYY')" />

</app-item>

</template>

<script>

export default {

	props: ['request', 'highlight']

}

</script>

<style scoped>

</style>
